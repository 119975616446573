import { css } from "@emotion/react"

export const styleFooter = css`
  background-color: #F8F8F8;
  font-family: 'Lato', sans-serif;
  padding-top: 70px;
  padding-bottom: 70px;
  border-top: 1px solid #E5E5E5;
  
  p {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #808080;

  }
`
export const styleFooter2 = css`
  background-color: #1E1E1E;
  font-family: 'Lato', sans-serif;
  padding-bottom: 52px;
  a {
    :hover {
      font-weight: bold;
      color: white;
    }
  }
`
export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
  a {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #555555;
    font-family: 'Montserrat', sans-serif;
  }
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const copy = css`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #EAEAEA;
  display: flex;
  align-items: center;
  img {
    margin-left: 48px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    img {
      margin-top: 16px;
      margin-left: 0px;
    }
  }
`

export const copy2 = css`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #EAEAEA;
  display: flex;
  align-items: center;
  img {
    margin-left: 48px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    img {
      margin-top: 16px;
      margin-left: 0px;
    }
  }
`

export const imgFooter = css`
  @media (min-width: 260px) and (max-width: 736px) {
    text-align: center !important;
  }

`

export const versarImg = css`
  width: 270px;
  @media (max-width: 480px) {
    width: 75%;
    margin-bottom: 28px;
  }
`

export const socialIcons = css`
  display: flex;
  margin-top: 24px;
  a {
    :not(:last-child) {
      margin-right: 28px;
    }
  }
`

export const borderLine = css`
  max-width: 270px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  background: #808080;
  height: 1px;
`

export const borderLineMobile = css`
  width: 100%;
  width: 100%;
  background: #808080;
  height: 1px;
`

export const footerItens = css`
  font-family: 'Open Sans', sans-serif;
  display: flex;
  h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #2E2D2C;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    :hover {
      color: #808080;
      font-weight: bold;
    }
  }
  ul {
    margin-bottom: 10px;
  }
  li {
    a {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #808080;
      margin-left: 8px;
      font-family: 'Open Sans', sans-serif;
      :hover {
        color: #808080;
        font-weight: bold;
      }
    }
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
  @media (max-width: 480px) {
    h2 {
      max-width: 148px;
    }
    li {
      a {
        margin-left: 0px ;
      }
    }
  }
`

export const adressItens = css`
  margin-left: 90px;
  display: flex;
  flex-direction: column;
  img {
    margin-right: 4px;
  }
`

export const adressItensMobile = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    max-width: 328px;
  }
  img {
    margin-right: 4px;
  }
`

export const desktop = css`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`

export const mobile = css`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 480px) {
    display: none;
  }
`