import { css } from "@emotion/react"

export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const linkTo = css`
  font-family: 'Lato', sans-serif;
  a {
    font-weight: 900;
    font-size: 48px;
    line-height: 58px;
    color: #F8F8F8 !important;
    text-decoration: none;
    :not(:last-child) {
      border-top: 1px solid #F8F8F8;
      border-bottom: 1px solid #F8F8F8;
      padding: 18px;
    }
    :hover {
      color: #FFC208 !important;
    }
  }
`

export const linksAcordion = css`
  margin-left: 100px;
  margin-top: 32px;
  margin-bottom: 32px;
  a {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #F8F8F8;
    font-family: 'Open Sans', sans-serif;
    :not(:last-child) {
      border-top: none;
      border-bottom: none;
      padding: 0px;
      margin-bottom: 32px;
    }
    :hover {
      color: #FFC208 !important;
    }
  }
`

export const linksAcordionMobile = css`
  margin-left: 100px;
  margin-top: 32px;
  margin-bottom: 32px;
  a {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #F8F8F8;
    font-family: 'Open Sans', sans-serif;
    :not(:last-child) {
      border-top: none;
      border-bottom: none;
      padding: 0px;
      margin-bottom: 32px;
    }
    :hover {
      color: #FFC208 !important;
    }
  }
  @media (max-width: 480px) {
    margin-left: 0px;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    a {
      padding-left: 24px !important;
      border: 0px;
    }
  }
`